// carsSlice.ts
import { ICar } from '../../interfaces/cars';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CarsState {
  cars: ICar[];
}

const initialState: CarsState = {
  cars: [],
};

const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    setCars: (state, action: PayloadAction<ICar[]>) => {
      state.cars = action.payload;
    },
    addCar: (state, action: PayloadAction<ICar>) => {
      state.cars.push(action.payload);
    },
    updateCar: (state, action: PayloadAction<{ id: number; updatedCar: Partial<ICar> }>) => {
      const { id, updatedCar } = action.payload;
      const carIndex = state.cars.findIndex((car) => car.id === id);

      if (carIndex !== -1) {
        state.cars[carIndex] = { ...state.cars[carIndex], ...updatedCar };
      }
    },
    removeCar: (state, action: PayloadAction<number>) => {
      state.cars = state.cars.filter((car) => car.id !== action.payload);
    },
  },
});

export const { setCars, addCar, updateCar, removeCar } = carsSlice.actions;

export default carsSlice.reducer;
