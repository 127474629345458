import { ICar } from "../interfaces/cars";
import { useGetCarsQuery } from "../features/cars/carsApi";
import { useEffect } from "react";
import { useDispatch } from "react-redux"
import { CarCard } from "../components/CarCard";

import { Row, Col, Button } from 'antd';
import { useNavigate } from "react-router-dom";


interface ICarsProps {

}



/**
 * 
 * @param props 
 * 
 * @returns 
 */
export const Cars = (props: ICarsProps) => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const { data: cars, error, isLoading } = useGetCarsQuery();




    return (
        <div className="page-container">
          <div className="page-header">
            <Row align="middle">
            <h1>Mes voitures</h1>
            <Button type="primary"
             style={{ marginLeft: 'auto' }}
            onClick={()=> {nav('/create-car')}}
             size="large">
              Ajouter une voiture
            </Button>

            </Row>
          </div>
          <Row gutter={[16, 16]}>
            {cars?.map((car: ICar) => (
              <Col key={car.id} xs={24} sm={12} md={8} lg={6} xl={6} span={8}>
                <CarCard car={car} />
              </Col>
            ))}
          </Row>
          
        </div>
      );
};


