// store.ts
import { configureStore, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { authApi } from './features/auth/authApi'; // Import the generated authApi from Redux Toolkit Query
import usersReducer from './features/users/userSlice';
import carsReducer from './features/cars/carsSlice';
import errorsReducer from './features/errors/errorSlice';
import connectionReducer from './features/connection/connectionSlice';
import authReducer from './features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { carsApi } from './features/cars/carsApi';

/* GLOBAL STORE OF THE APPLICATION */
const middlewares: Middleware[] = [
  // ...other middlewares
  authApi.middleware, // Add the Redux Toolkit Query middleware for authApi
  carsApi.middleware, // Add the Redux Toolkit Query middleware for authApi
];

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    cars: carsReducer,
    error: errorsReducer,
    connection: connectionReducer,
    [authApi.reducerPath]: authApi.reducer, 
    [carsApi.reducerPath]: carsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares),
});

// Setup listeners for automatic refetching on query invalidation
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
