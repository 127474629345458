import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';

const { Meta } = Card;


interface ISharedCardProps {
  picture: string;
  description: string;
  handleEditClick: ()=> void;
  handleDeleteCLick:  ()=> void;
  title: string;
}






const SharedCard = ({picture, title, description, handleEditClick, handleDeleteCLick}: ISharedCardProps) => (
  <Card
    style={{ width: 300 }}
    cover={
      <img
        alt="example"
        style={{maxWidth:"10rem", margin:'auto', marginTop:"2rem"}}
        src={picture}
      />
    }
    actions={[
      
      <EditOutlined 
      onClick={handleEditClick}
      key="edit" />,
      <DeleteOutlined 
      style={{color:"red"}}
   
      onClick={handleDeleteCLick}
      key="delete" />
    ]}
  >
    <Meta
      title={title}
      description={description}
    />
  </Card>
);

export default SharedCard;