// authApi.ts
import APP_CONSTANTS from '../../constants';
import { ILoginCredentials } from '../../interfaces/auth';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: APP_CONSTANTS.SERVER_URL }), // Adjust the base URL as needed
  endpoints: (builder) => ({
    // Define your authentication-related endpoints and queries here
    // Example:
    loginUser: builder.mutation<any, ILoginCredentials>({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    // Add more authentication-related endpoints as needed
  }),
});

export const { useLoginUserMutation } = authApi;

// Export the hooks for use in components
export const useAuthApi = () => authApi;
