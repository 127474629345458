// carsApi.ts
import APP_CONSTANTS from '../../constants';
import { ICar } from '../../interfaces/cars';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';




export const carsApi = createApi({
  reducerPath: 'carsApi',
  baseQuery: fetchBaseQuery({ baseUrl: APP_CONSTANTS.SERVER_URL }),
  endpoints: (builder) => ({
    getCars: builder.query<Array<ICar>, void>({
      query: () => '/cars',
      // transformResponse: (response: any) => response.data, // Unwrap the data field

    }),
    getCarById: builder.query<Array<ICar>, number>({
      query: (id) => `cars/${id}`,
    }),
    createCar: builder.mutation<ICar, Partial<ICar>>({
      query: (newCar) => ({
        url: 'cars',
        method: 'POST',
        body: newCar,
      }),
    }),
    updateCar: builder.mutation<ICar, { id: number; updatedCar: Partial<ICar> }>({
      query: ({ id, updatedCar }) => ({
        url: `cars/${id}`,
        method: 'PUT',
        body: updatedCar,
      }),
    }),
    deleteCar: builder.mutation<void, number>({
      query: (id) => ({
        url: `cars/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { useGetCarsQuery, useGetCarByIdQuery, useCreateCarMutation, useUpdateCarMutation, useDeleteCarMutation } = carsApi;
