import { Menu } from "antd"
import SubMenu from "antd/es/menu/SubMenu"
import { Link, useNavigate } from "react-router-dom";
import { LogoutOutlined } from '@ant-design/icons';

export const Header = () => {


  const nav = useNavigate();


  const handleDisconnectClick = () => {
    localStorage.removeItem('isAuth');
    nav('/login');
  }

    return (
        <Menu mode="horizontal">
          {/* Logo of the app */}
          <Menu.Item key="logo">
            <span>Exclusif car</span>
          </Menu.Item>
    
          {/* Normal menu items */}
          <Menu.Item key="cars">
            <Link to="/cars">Mes voitures</Link>
          </Menu.Item>
{/*     
          <Menu.Item key="orders">
            <Link to="/orders">Mes commandes</Link>
          </Menu.Item> */}
          <Menu.Item key="disconnect" 
          onClick={handleDisconnectClick}
          icon={<LogoutOutlined />}
           style={{ marginLeft: 'auto', color:"red" }}>
        Deconnexion
      </Menu.Item>
        </Menu>
      );
}