import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from 'axios';
import APP_CONSTANTS from '../constants';
import { refreshToken } from '../services/token.service';
import useErrorDispatch from '../hooks/useErrorDispatch';

class AxiosInterceptor {
  private static instance: AxiosInterceptor;
  private axiosInstance: AxiosInstance;

  private constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.SERVER_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    this.setupRequestInterceptor();
    this.setupResponseInterceptor();
  }

  public static getInstance(): AxiosInterceptor {
    if (!AxiosInterceptor.instance) {
      AxiosInterceptor.instance = new AxiosInterceptor();
    }
    return AxiosInterceptor.instance;
  }

  private setupRequestInterceptor() {
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const token = localStorage.getItem(
          `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
        );
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
  }

  private setupResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const { response, config } = error;

        if (response) {
          switch (response.status) {
            case 401:
              if (config && !config.retryAttempt) {
                config.retryAttempt = 1;
                const newAccessToken = await refreshToken();
                if (config.headers) {
                  config.headers.Authorization = `Bearer ${newAccessToken}`;
                }
                return this.axiosInstance(config);
              }
              break;
            default: {
              const dispatchError = useErrorDispatch();
              dispatchError(error.message || 'Network Error');
              return;
            }
          }
        }

        return Promise.reject(error);
      }
    );
  }

  public getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }
}

const interceptorInstance = AxiosInterceptor.getInstance();
const instance = interceptorInstance.getAxiosInstance();

export default instance;
