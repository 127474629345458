// connectionSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from '@interfaces/app';
import { SLICE } from '../../enums/store';

const initialState: AuthState = {

  connectedUser: localStorage.getItem("isAuth") === "true" || false,
};

const connectionSlice = createSlice({
  name: SLICE.AUTH,
  initialState,
  reducers: {
    setConnectedUser: (state, action: PayloadAction<boolean>) => {
      state.connectedUser = action.payload;
    },
  },
});

export const { setConnectedUser } = connectionSlice.actions;
export default connectionSlice.reducer;
