

import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useLoginUserMutation } from '../features/auth/authApi';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import exclusifCarLogo from '../assets/img/logo-exclusif-car.png'
import { setConnectedUser } from '../features/auth/authSlice';

interface ILoginProps {

}


export const Login = (props: ILoginProps) => {


  const nav = useNavigate();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const [loginUser, { isLoading, isError, error }] = useLoginUserMutation();





  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      // Perform the login mutation
      const credentials = { email: values.email, password: values.password };
      const result = await loginUser(credentials).unwrap();
      setLoading(false);

      localStorage.setItem('isAuth', 'true');

      nav(`/cars`);
    } catch (error) {
      // Handle login error
      setLoading(false);

    }
  };

  return (<div
    style={{ display: 'flex',
    flexDirection:"column",
     justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <img
        width={"10%"}
        src={exclusifCarLogo} alt="" 
        style={{ marginBottom: '2rem' }} // Adjust the margin as needed
      />
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "l'email est obligatoire",
          },
        ]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "le mot de passe est obligatoire",
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>


      <Form.Item>
        <Button
        style={{width:"100%"}}
        loading={loading}
          type="primary" htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form.Item>
    </Form>
  </div>)
}