// GlobalLayout.tsx
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Header } from './Header';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';




const GlobalLayout: React.FC = () => {

  const connectedUser = useSelector(((state: RootState) => state.auth.connectedUser));



  return (
    <div >
 <Header />
      <main
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "1200px", /* Adjust the max-width as needed */
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
       className="page-container">
        <Outlet /> {/* Renders the content specific to each route */}
      </main>
    </div>
  );
};

export default GlobalLayout;
