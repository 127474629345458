import { useDispatch } from 'react-redux';
import { setError } from '../features/errors/errorSlice';




const useErrorDispatch = () => {
  const dispatch = useDispatch();

  const dispatchError = (error: Error) => {
    dispatch(setError(error));
  };

  return dispatchError;
};

export default useErrorDispatch;
