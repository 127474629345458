import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { IUser, ICreateUser, IUpdateUser } from '@interfaces/user';
import { AxiosResponse } from 'axios';
import { SLICE, LOADING_STATE, USER_ACTION_TYPES } from '../../enums/store';
import { getUserById, createUser, updateUser, getAllUsers } from '../../services/users.service';


interface UsersState {
    entities: IUser[];
    loading: LOADING_STATE.IDLE | LOADING_STATE.PENDING | LOADING_STATE.SUCCEEDED | LOADING_STATE.FAILED;
}

const initialState: UsersState = {
    entities: [],
    loading: LOADING_STATE.IDLE,
};


/**
 * @description get all users
 */
export const fetchAllUsers = createAsyncThunk(
    USER_ACTION_TYPES.FETCH_ALL_USERS,
    async () => {
        const response: AxiosResponse<Array<IUser>> = await getAllUsers();
        return response.data;
    }
);


/**
 * @description get user by id
 */
export const fetchUserById = createAsyncThunk(
    USER_ACTION_TYPES.FETCH_BY_ID,
    async (userId: number) => {
        const response: AxiosResponse<IUser> = await getUserById(userId);
        return response.data;
    }
);


/**
 * @description create a new user
 */
export const createNewUser = createAsyncThunk(
    USER_ACTION_TYPES.CREATE,
    async (userData: ICreateUser) => {
        const response: AxiosResponse<IUser> = await createUser(userData);
        return response.data;
    }
);

/**
 * @description update a users
 */
export const updateExistingUser = createAsyncThunk(
    USER_ACTION_TYPES.UPDATE,
    async ({ id, data }: { id: number; data: IUpdateUser }) => {
        const response: AxiosResponse<IUser> = await updateUser(data, id);
        return response.data;
    }
);

/**
 * @description Creating new slice
 */
const usersSlice = createSlice({
    name: SLICE.USERS,
    initialState,
    reducers: {
        resetUsers(state) {
            state.entities = [];
        },
    },
    extraReducers: (builder) => {
        // get all users
        builder.addCase(fetchAllUsers.pending, (state) => {
            state.loading = LOADING_STATE.PENDING;
        });
        builder.addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
            state.loading = LOADING_STATE.SUCCEEDED;
            state.entities = payload;
        });
        builder.addCase(fetchAllUsers.rejected, (state) => {
            state.loading = LOADING_STATE.FAILED;
        });

        // get user by id
        builder.addCase(fetchUserById.pending, (state) => {
            state.loading = LOADING_STATE.PENDING;
        });
        builder.addCase(fetchUserById.fulfilled, (state, { payload }) => {
            state.loading = LOADING_STATE.SUCCEEDED;
            state.entities.push(payload);
        });
        builder.addCase(fetchUserById.rejected, (state) => {
            state.loading = LOADING_STATE.FAILED;
        });

        // create a user
        builder.addCase(createNewUser.pending, (state) => {
            state.loading = LOADING_STATE.PENDING;
        });
        builder.addCase(createNewUser.fulfilled, (state, { payload }) => {
            state.loading = LOADING_STATE.SUCCEEDED;
            state.entities.push(payload);
        });
        builder.addCase(createNewUser.rejected, (state) => {
            state.loading = LOADING_STATE.FAILED;
        });

        // update a user
        builder.addCase(updateExistingUser.pending, (state) => {
            state.loading = LOADING_STATE.PENDING
        });
        builder.addCase(updateExistingUser.fulfilled, (state, { payload }) => {
            state.loading = LOADING_STATE.SUCCEEDED;
            const existingIndex = state.entities.findIndex(entity => entity.id === payload.id);
            if (existingIndex !== -1) {
                state.entities[existingIndex] = payload;
            }
        });
        builder.addCase(updateExistingUser.rejected, (state) => {
            state.loading = LOADING_STATE.FAILED;
        });
    },
});

export const { resetUsers } = usersSlice.actions;

export default usersSlice.reducer;
