

/* DEFINING POSSIBLE VALUES FOR LOADING STATE */
export enum LOADING_STATE {
    IDLE = "idle",
    PENDING = "pending",
    SUCCEEDED = "succeeded",
    FAILED = "failed",
}

/* DEFINING STORE SLICES */
export enum SLICE {

    /* basic slices */
    CONNECTION = 'connection', // this slice is to detect if the internet connection is up or down
    AUTH = 'auth',
    USERS = "users",

    /* business slices */
    MEDICATIONS = "medications",
    APPOINTMENTS = "appointments",


}

/* DEFINING ACTION TYPES */
export enum USER_ACTION_TYPES {
    FETCH_ALL_USERS = 'users/fetchAllUsers',
    FETCH_BY_ID = 'users/fetchById',
    CREATE = 'users/create',
    DELETE = 'users/delete',
    UPDATE = 'users/update'
}