
import { useNavigate } from "react-router-dom";
import { ICar } from "../interfaces/cars";
import SharedCard from "./shared/Card";
import { useDeleteCarMutation } from "../features/cars/carsApi";
import { toast } from "react-toastify";
import axios from "axios";
import APP_CONSTANTS from "../constants";



/**
 * 
 */
interface ICarCardProps {
    car: Partial<ICar>;

}


/**
 * 
 * @param param0 
 * @returns 
 */
export const CarCard = ({ car }: ICarCardProps) => {

    const nav = useNavigate();


    /**
     * 
     */
    const handleEditCarClick = () => {
        nav(`/update-car/${car.id}`);
    }


    /**
     * 
     */
    const handleDeleteCarClick = async () => {
        const confirmAction = window.confirm('Êtes vous sûr de bien vouloir supprimer la voiture?');
        if (confirmAction) {
            {
                await axios.delete(`${APP_CONSTANTS.SERVER_URL}/cars/${car.id}`).then(res => {
                    toast.success("la voiture est supprimé");
                    window.location.pathname = "/cars"

                }).catch(err => {
                    toast.error("Une erreur est survenue")
                    console.error('Error deleting car:', err);
                });
            }
        };

    }



    return (<>
        <SharedCard
            handleEditClick={handleEditCarClick}
            handleDeleteCLick={handleDeleteCarClick}
            picture={`${APP_CONSTANTS.STORAGE_URL}${car.picture!}`}
            title={`${car.company!} ${car.name!}`}
            description={`prix par jour: ${car.price_per_day_1_10}€ pour moins de 10 jours, et ${car.price_per_day_10_20} € pour plus de 10 jours `}
        />


    </>)
}