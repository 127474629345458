import { AxiosResponse } from "axios";
import { IUser, ICreateUser, IUpdateUser } from "@interfaces/user";
import instance from "../config/axios.config";




/**
 * 
 * @param userId 
 * @param query 
 * @returns 
 */
export const getAllUsers = async (query?: string) : Promise<AxiosResponse<Array<IUser>>> => {
  return await instance.get(`/user`)
}


/**
 * 
 * @param userId 
 * @param query 
 * @returns 
 */
export const getUserById = async (userId: number, query?: string) : Promise<AxiosResponse<IUser>> => {
  return query ? await instance.get(`/user/${userId}?${query}`) : await instance.get(`/user/${userId}`);
}

/**
 *
 * @param user
 * @returns
 */
export const createUser = async (
  user: ICreateUser
) : Promise<AxiosResponse<IUser>> => {
  return await instance.post(`/user`, user);
};


/**
 *
 * @param updatedUser
 * @param id
 * @returns
 */
export const updateUser = async (
    updatedUser: IUpdateUser,
    id: number
  ) : Promise<AxiosResponse<IUser>>=> {
    console.log("sending form", updatedUser);
    return await instance.patch(`/user/${id}`, updatedUser);
  };


