import { Row } from "antd";
import CarForm from "../components/forms/CarForm"
import { useCreateCarMutation } from "../features/cars/carsApi";

interface ICreateCarProps {

}


export const CreateCar = (props: ICreateCarProps) => {



    return (<>
        <div className="page-header">
            <Row align="middle">
                <h1>Ajouter une voiture</h1>
            </Row>
        </div>
        <CarForm
            isUpdateForm={false}
        />
    </>)
}