import { toast } from 'react-toastify';
import { AppDispatch } from '../store'; // Replace with your store configuration

export const setupGlobalErrorHandler = (dispatch: AppDispatch) => {
  window.addEventListener('error', (event) => {
    const { message, filename, lineno, colno, error } = event;
    const errorMessage = error ? error.toString() : message;

    // Handle the error here, e.g., log it, send it to the server, etc.

    // Dispatch an action to update the Redux store with the error
    dispatch({ type: 'SET_GLOBAL_ERROR', payload: errorMessage });

    // Show a toast notification to the user
    toast.error('An error occurred. Please try again.');

    // Optionally, you can also log the error to the console
    console.error(errorMessage, filename, lineno, colno, error);
  });
};
