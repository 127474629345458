// HERE WE DEFINE ALL APP CONSTANTS
const APP_CONSTANTS =  {
  LOCALSTORAGE_PREFIX: "far_",

  SERVER_URL: "https://api.exclusifcar.com/public/index.php/api/",
  STORAGE_URL: "https://api.exclusifcar.com/public/storage/",
  // SERVER_URL: "http://localhost:8000/api",
  // STORAGE_URL:"http://localhost:8000/public/storage/"
  // APP_URL:"http://localhost:3000",
}

export default APP_CONSTANTS;


