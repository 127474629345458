import { useEffect, useState } from "react";
import CarForm from "../components/forms/CarForm";
import { useGetCarByIdQuery, useUpdateCarMutation } from "../features/cars/carsApi";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ICar } from "@interfaces/cars";
import { Row } from "antd";

interface IUpdateCarProps {
}


export const UpdateCar = ({ }: IUpdateCarProps) => {


    const { id } = useParams();
    const [car, setCar] = useState<ICar | null>(null);
    const [carId, setCarId] = useState<number | null>(null);
    const dispatch = useDispatch();
    const nav = useNavigate();

    const { data: cars, error, isLoading, refetch } = useGetCarByIdQuery(Number(carId));


    useEffect(() => {
        
        if (id) setCarId(Number(id))
    }, [id])


    useEffect(()=> {
        refetch();
        console.log("car id changed", carId)
    }, [carId])

    useEffect(()=> {
        console.log("car changed", car)
    }, [car])

    useEffect(()=> {
        console.log("car loading", isLoading)
        if(!isLoading && cars) setCar(cars[0]);
    }, [cars,carId])




    if (!car) return (<>loading</>)
    else return (<>
            <div className="page-header">
            <Row align="middle">
                <h1>Modifier la voiture</h1>
            </Row>
        </div>
        <CarForm 
        isUpdateForm={true}
        car={car!} />
    </>)
}