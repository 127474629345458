import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { setupGlobalErrorHandler } from './config';
import { AppDispatch, RootState } from './store';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import useConnection from './hooks/useConnection';
import { Cars } from './pages/Cars';
import { Login } from './pages/Login';
import { Orders } from './pages/Orders';
import GlobalLayout from './components/layout/GLobalLayout';
import { UpdateCar } from './pages/UpdateCar';
import { CreateCar } from './pages/CreateCar';
import { setConnectedUser } from './features/auth/authSlice';




function App() {


  const dispatch = useDispatch<AppDispatch>();
  const connectedUser = useSelector(((state: RootState) => state.auth.connectedUser));

  useEffect(() => {
    if(!connectedUser && window.location.pathname != "/login") window.location.pathname = "/login";
    if(connectedUser) {
      const pathname = window.location.pathname;
      if (pathname === '/') {
        window.location.pathname = '/cars';
      }

    }

  }, [connectedUser])



  useEffect(() => {
    setupGlobalErrorHandler(dispatch);
  }, [dispatch]);


  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        
        <Route path="/" element={<GlobalLayout />}>
          <Route path="/cars" element={<Cars />} />
          <Route path="/update-car/:id" element={<UpdateCar />} />
          <Route path="/create-car" element={<CreateCar />} />
          <Route path="/orders" element={<Orders />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
