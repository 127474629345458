import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Upload, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ICar } from '@interfaces/cars';
import { useCreateCarMutation, useUpdateCarMutation } from '../../features/cars/carsApi';
import { toast } from 'react-toastify';
import {  notification } from 'antd';


interface ICarFormProps {
    car?: ICar;
    isUpdateForm: boolean;
}

const CarForm = ({ isUpdateForm, car }: ICarFormProps) => {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [createCarMutation, { isLoading, isError }] = useCreateCarMutation();
    const [updateCarMutation, { isLoading: isUpdateLoading, isError: isUpdateError }] = useUpdateCarMutation();
    const [previewImage, setPreviewImage] = useState<any>(car?.picture);
    const [changedCarValues, setChangedCarValues] = useState({});



    /**
     * 
     * @param param0 
     */
    const openNotification = ({title, description}: {title: string, description: string}) => {
        api.open({
          message: title,
          description:description,
          duration: 0,
        });
      };


    /**
     * 
     * @param changedValues 
     */
    const handleCarInformationsChange = (changedValues: any) => {
        setChangedCarValues({ ...changedValues, ...changedCarValues });
    };

    /**
     * 
     * @param file 
     * @returns 
     */
    const beforeUpload = (file: File) => {
        // Display image preview for the first time
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
        return false; // Prevent actual file upload
    };


    /**
     * 
     * @param carData 
     */
    const handleUpdateCar = async (carData: ICar) => {
        try {

            const result = await updateCarMutation({ id: car?.id!, updatedCar: carData }).unwrap();
            // Handle success, e.g., redirect to a new page or show a success message
            openNotification({title:"La voiture a été bien modifié",description:"V"});
            setTimeout(() => {
                window.location.pathname = "/cars";
            }, 800)
        } catch (error) {
            // Handle error, e.g., show an error message
            openNotification({title:"Erreur",description:"Une erreur est survenue, veuillez réeassayer"});
        }
    };



    const handleCreateCar = async (carData: any) => {
        try {
            const result = await createCarMutation(carData).unwrap();
            // Handle success, e.g., redirect to a new page or show a success message
           openNotification({title:"La voiture est bien crée",description:"V"});
            setTimeout(() => {
                window.location.pathname = "/cars";
            }, 800)
        } catch (error) {
            // Handle error, e.g., show an error message
            toast.error("Une erreur est survenue lors de la creation");
        }
    };



    const onFinish = (values: any) => {
        if (isUpdateForm) handleUpdateCar(values);
        else handleCreateCar(values)
    };

    /* update form */
     return (
        <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={handleCarInformationsChange}

            initialValues={isUpdateForm ? {
                // Set initial values based on the car object
                picture: car?.picture,
                class_id: 1,
                company: car?.company,
                name: car?.name,
                deposit: car?.deposit,
                seats: car?.seats ?? 5,
                max_passengers: car?.max_passengers ?? 5,
                milleage: car?.milleage ?? 0,
                doors: car?.doors,
                gearbox: car?.gearbox,
                franchise: car?.franchise,
                price_per_day_1_10: car?.price_per_day_1_10,
                price_per_day_10_20: car?.price_per_day_10_20,
                fuel: car?.fuel,
                min_drive_age: car?.min_drive_age,
                min_rental_days: car?.min_rental_days,
                quantity: car?.quantity,
            } : {
                min_drive_age: 18,
                min_rental_days: 2,
                seats: 5,
                deposit: 0,
                franchise: 0,
                gearbox: "manual",
                fuel: "petrol",
                milleage: "0"
            }}
        >
            <div className="form form-group">
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{ textAlign: 'center', marginBottom: '16px' }}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "L'image de la voiture est obligatoire",
                                },
                            ]}

                            name="picture" label="Image">
                            <Row justify="center" align="middle">
                                <Col span={12} offset={6}>
                                    {previewImage && (
                                        <img
                                            src={
                                                car?.picture === previewImage
                                                    ? "https://api.exclusifcar.com/public/storage/" + previewImage
                                                    : previewImage
                                            }
                                            alt="Car Preview"
                                            style={{
                                                maxWidth: '10rem',
                                                marginTop: '8px',
                                            }}
                                        />
                                    )}
                                </Col>
                                <Col span={12} offset={6}>
                                    <Upload
                                        beforeUpload={beforeUpload}
                                        listType="picture"
                                        showUploadList={false}
                                        customRequest={({ file }) => {
                                            // Handle file upload
                                            // You can use your own logic to upload the file to the server
                                            console.log('File uploaded:', file);
                                        }}
                                    >
                                        <Button icon={<UploadOutlined />}>Upload Image</Button>
                                    </Upload>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le nom de l'entreprise est obligatoire (Porshe, Renault..)",
                                },
                            ]}

                            name="company" label="Entreprise">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le nom de la voiture est obligatoire (Cayenne, clio,...)",
                                },
                            ]}

                            name="name" label="Nom de la voiture">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le deposite est obligatoire",
                                },
                            ]}

                            name="deposit" label="Deposit">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le nombre de portes de la voiture est obligatoire",
                                },
                            ]}
                            name="doors" label="Nombre de portes">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le nombre maximal des passengers de la voiture est obligatoire",
                                },
                            ]}

                            name="max_passengers" label="Nombre de personnes">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le nombre de place est obligatoire",
                                },
                            ]}
                            name="seats" label="Nombre de places">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le kilomètrage de la voiture est obligatoire",
                                },
                            ]}
                            name="milleage" label="Kilometrage">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "La franchise est obligatoire",
                                },
                            ]}
                            name="franchise" label="Franchise">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le prix de moins de 10 jours  est obligatoire",
                                },
                            ]}
                            name="price_per_day_1_10" label="Prix de 1 à 10 jours (€)">
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le prix de plus de 10 jours  est obligatoire",
                                },
                            ]}
                            name="price_per_day_10_20" label="Prix de plus de 10 jours (€)">
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le carburant de la voiture  est obligatoire",
                                },
                            ]}
                            name="fuel" label="Carburant">
                            <Select>
                                <Select.Option value="fuel">Essence</Select.Option>
                                <Select.Option value="petrol">Diesel</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="class_id" label="Classe de voiture">
                            <Select>
                                <Select.Option value="1">class 1</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "La boite à vitesse est obligatoire",
                                },
                            ]}
                            name="gearbox" label="Boite à vitesse">
                            <Select>
                                <Select.Option value="automatic">Automatique</Select.Option>
                                <Select.Option value="manual">Manuel</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "L'age minimal de conduite  est obligatoire",
                                },
                            ]}
                            name="min_drive_age" label="Minimum âge">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Le nombre minimal de location est obligatoire",
                                },
                            ]}
                            name="min_rental_days" label="Minimum jours de location">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "La quantité de cette voiture disponible est obligatoire",
                                },
                            ]}

                            name="quantity" label="Quantité disponible">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center', marginBottom: '16px' }}>
                        <Form.Item>
                            <Button
                            loading={isLoading || isUpdateLoading}
                            
                            type="primary" htmlType="submit">
                                Sauvegarder les changements
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </Form>
    );

};

export default CarForm;
